<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateEmployeesFoldersMedia()"
    >
      <div class="row">
        <CustomFileInput
          :className="'col-12'"
          @changeValue="
            folderMediaData.media = $event.file;
            folderMediaData.mediaTypeToken = $event.mediaTypeToken;
          "
          :defaultImg="defaultImg"
        />
      </div>

      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="'col-md-12'"
            :id="'employeeToken'"
            :value="folderMediaData.employeeToken"
            :options="employeeTokenOptions"
            v-on:changeValue="changeEmployee($event)"
            :title="$t('selectEmployee')"
            :imgName="'man.svg'"
          />
          <!-- v-if="!isLoading" -->
          <CustomSelectBox
            :className="'col-md-12'"
            :id="'employeeFolderToken'"
            :value="folderMediaData.employeeFolderToken"
            :options="employeeFolderTokenOptions"
            v-on:changeValue="folderMediaData.employeeFolderToken = $event"
            :title="$t('employeeFolders.select')"
            :imgName="'media.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'folderMediaNameAr'"
            :value="folderMediaData.folderMediaNameAr"
            v-on:changeValue="folderMediaData.folderMediaNameAr = $event"
            :title="$t('foldersMedias.nameAr')"
            :imgName="'media.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'folderMediaNameEn'"
            :value="folderMediaData.folderMediaNameEn"
            v-on:changeValue="folderMediaData.folderMediaNameEn = $event"
            :title="$t('foldersMedias.nameEn')"
            :imgName="'media.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'folderMediaNameUnd'"
            :value="folderMediaData.folderMediaNameUnd"
            v-on:changeValue="folderMediaData.folderMediaNameUnd = $event"
            :title="$t('foldersMedias.nameUnd')"
            :imgName="'media.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'folderMediaCode'"
            :value="folderMediaData.folderMediaCode"
            v-on:changeValue="folderMediaData.folderMediaCode = $event"
            :title="$t('foldersMedias.code')"
            :imgName="'number.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'folderMediaDescriptionAr'"
            :value="folderMediaData.folderMediaDescriptionAr"
            v-on:changeValue="folderMediaData.folderMediaDescriptionAr = $event"
            :title="$t('foldersMedias.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'folderMediaDescriptionEn'"
            :value="folderMediaData.folderMediaDescriptionEn"
            v-on:changeValue="folderMediaData.folderMediaDescriptionEn = $event"
            :title="$t('foldersMedias.descriptionEn')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'folderMediaDescriptionUnd'"
            :value="folderMediaData.folderMediaDescriptionUnd"
            v-on:changeValue="
              folderMediaData.folderMediaDescriptionUnd = $event
            "
            :title="$t('foldersMedias.descriptionUnd')"
            :imgName="'description.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'folderMediaNotes'"
            :value="folderMediaData.folderMediaNotes"
            v-on:changeValue="folderMediaData.folderMediaNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link
          :to="{ name: 'EmployeesFoldersMedias' }"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import CustomFileInput from "@/components/general/CustomFileInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import { STATUS } from "@/utils/constants";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import EmployeeFolders from "@/models/employees/employeeFolders/EmployeeFolders";
import Employee from "@/models/employees/employees/Employee";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomFileInput,
    TextArea,
    CustomSelectBox,
    CustomInput,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employeeFolders: new EmployeeFolders(),
      employeeFolderTokenOptions: [],
      employeeData: new Employee(),
      employeeTokenOptions: [],
    };
  },
  props: ["folderMediaData", "defaultImg", "submitName"],
  methods: {
    async getDialogOfServicesReservations() {
      this.isLoading = true;
      this.folderTokenOptions = [];
      try {
        let response = await this.folderData.getDialogOfServicesReservations(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;

        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.folderTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfEmployeeFolders() {
      this.isLoading = true;
      this.employeeFolders.filterData.employeeToken =
        this.folderMediaData.employeeToken;
      this.employeeFolderTokenOptions = [];
      try {
        let response =
          await this.employeeFolders.employeeFolder.getDialogOfEmployeeFolders(
            this.language,
            this.userAuthorizeToken,
            this.employeeFolders.filterData
          );
        if (response.data.status == STATUS.SUCCESS) {
          const itemsData = response.data.itemsData;
          for (let item in itemsData) {
            this.employeeFolderTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    changeEmployee(data) {
      this.folderMediaData.employeeToken = data;
      this.folderMediaData.employeeFolderToken = "";
      this.getDialogOfEmployeeFolders();
    },
    async getDialogOfEmployees() {
      this.isLoading = true;
      this.employeeTokenOptions = [];
      try {
        let response = await this.employeeData.getDialogOfEmployees(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          const itemsData = response.data.itemsData;
          for (let item in itemsData) {
            this.employeeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("updateUserData", null);
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async addOrUpdateEmployeesFoldersMedia() {
      this.$emit("addOrUpdateEmployeesFoldersMedia");
    },
  },
  watch: {},
  async created() {
    this.getDialogOfEmployeeFolders();
    this.getDialogOfEmployees();
  },
};
</script>
